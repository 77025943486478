@import '_mixins';
@import '_reset';

@include fontFace('Bebas', './../fonts/BebasNeueBold');
@include fontFace('OpenSans', './../fonts/OpenSans');
@include fontFace('OpenSansLight', './../fonts/OpenSans-Light');
@include fontFace('OpenSansSemiBold', './../fonts/OpenSans-Semibold');
@include fontFace('OpenSansBold', './../fonts/OpenSans-Bold');
@include fontFace('OpenSansExtrabold', './../fonts/OpenSans-Extrabold');

$white-color:					#fff;
$black-color: 					#000;
$blue-color:					#003485;
$dblue-color:					#192d4c;
$green-color:					#03a944;
$ggreen-color:					#039a3e;
$sgreen-color:					#008835;

body {
	background:					url(./../img/bg.jpg);
	@include fontStyle (16px, OpenSans, $dblue-color);
	@include backgroundStyle(center, cover, repeat);
	padding-top: 				135px; }

a {
	color: 						$white-color;
	text-decoration: 			none;

	&:hover {
		color: 					$white-color;
		text-decoration: 		none; } }

.bg-fix {
	position: 					fixed;
	left: 						0px;
	top: 						0px;
	width: 						100%;
	height: 					100%;
	background:					url(./../img/bg.jpg);
	@include backgroundStyle(center, cover, repeat);
	z-index: 					-1; }

.head {
	position: 					fixed;
	top: 						0px;
	background: 				$blue-color;
	z-index: 					1;

	.head-container {
		padding-top: 			10px;
		padding-bottom: 		10px; } }

.logo {

	img {
		width: 					150px;
		@media screen and (max-width: 500px) {
			width: 				100px; } }

	span {
		display: 				block;
		@include fontStyle (18px, OpenSans, $white-color); } }

.contacts {
	text-align: 				right;
	line-height: 				20px;
	@include fontStyle (16px, OpenSans, $white-color);
	@media screen and (max-width: 500px) {
		font-size: 				11px; } }

.title {
	text-align: 				center;

	h1 {
		@include fontStyle (70px, OpenSansBold, $blue-color);
		@media screen and (max-width: 1000px) {
			font-size: 			25px; }

		span {
			color: 			$green-color; } } }

.points {
	margin-top: 				20px;

	.media {
		margin-bottom: 			20px; }

	span {
		@include fontStyle (20px, OpenSansSemiBold, $blue-color);
		@media screen and (max-width: 1000px) {
			margin-top: 		0px!important;
			font-size: 			16px; } }

	img {
		@media screen and (max-width: 1000px) {
			width: 				30px; } } }

.cta {
	margin-top: 				30px; }

.cta-box {
	text-align: 				center; }

h5 {
	@include fontStyle (30px, OpenSansBold, $blue-color);
	@media screen and (max-width: 500px) {
			font-size: 			18px; } }

.button {
	display: 					inline-block;
	padding: 					20px 50px;
	background: 				linear-gradient(to bottom, $green-color, $ggreen-color);
	box-shadow: 				$sgreen-color 0 5px 0 0;
	margin-top: 				20px;
	margin-bottom: 				30px;
	@include fontStyle (20px, OpenSansBold, $white-color);
	@include transitionStyle(400ms);
	@media screen and (max-width: 430px) {
			font-size: 			15px;
			padding: 			10px 20px; }

	&:hover {
		box-shadow: 			none; } }

.footer {
	margin-top: 				40px;
	padding: 					20px 0px;
	background: 				$dblue-color;

	span, a {
		display: 				block;
		@include fontStyle (14px, OpenSansLight, $white-color); } }

.contacts-footer {
	text-align: 				right; }
